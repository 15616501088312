import {RemixBrowser, useLocation, useMatches} from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import {StrictMode, startTransition, useEffect} from 'react'
import {hydrateRoot} from 'react-dom/client'
import {SENTRY_ENVIRONMENT} from './utils/sentry.env'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://55d735dfd0554c24b843558a001e8834@o1152282.ingest.sentry.io/6248934',
    integrations: [
      Sentry.browserTracingIntegration({useEffect, useLocation, useMatches}),
      // TODO: enable this once PostHog updates their Sentry integration
      // new posthog.SentryIntegration(posthog, 'depot', 6248934),
    ],
    tracesSampleRate: 1.0,
    tunnel: '/api/sentry',
    environment: SENTRY_ENVIRONMENT,
    allowUrls: ['depot.dev', 'preview.depot.dev'],
  })
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
